/* 
general 
-------
*/
body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  /* overflow-x: hidden; */
  font-family: 'Poppins',Arial, Helvetica, sans-serif;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  /* padding: 0 15px; */
}

.ant-layout {
  background: #fff !important;
}



p {
  font-size: 16px;
  line-height: 1.6;
}

.bgGray {
  background: #fafafa;
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: '';
  background: #1890ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header .logo {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
}

.header .logo .fas {
  color: #1890ff;
  margin: 0 5px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #333;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.ant-anchor-link-active,.ant-anchor-link-title{
  color: #fafafa !important;
  text-transform: uppercase;
}

/* .header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
} */

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

/* .header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
} */

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  /* color: #333; */
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #1890ff;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
hero
---- 
*/
.heroBlock {
  /* background: url('assets/images/bg-hero.jpg') no-repeat; */
  background-position: 50% 100%;
  background-size: cover;
  height: 100vh;
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

/* .heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
} */

.heroBlock .content {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
}

.heroBlock p {
  margin: 0 0 30px;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}

.aboutBlock .icon {
  font-size: 40px;
  color: #1890ff;
  margin: 0 0 10px;
}

.aboutBlock .content {
  text-align: center;
}

/* 
how it works
------------
*/
.worksBlock {
  /* background: url('assets/images/bg-works.jpg') no-repeat; */
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.worksBlock:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock h2 {
  color: #fff;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder button {
  font-size: 40px;
  line-height: 1;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 30px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}

/* 
faqs
----
*/
.faqBlock .ant-collapse {
  margin: 0 0 40px;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

/* .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
} */

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
.contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #161a42 !important;
  color: #fff !important;
  padding: 60px 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #1890ff;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }
  
  .mobileHidden {
    display: none;
  }
}

.buttons{
  margin-right: 25px !important;
}

.ck-editor__editable {
  min-height: 300px !important;
}

.editorClassName{
  min-height: 350px;
}

.header-link{
  padding: 15px;
  color: #333;
  font-size: 16px;
}
.cta-btn{
  padding: 15px !important;
  height: auto !important;
}
.cta-btn-login{
  padding: 10px !important;
  height: auto !important;
  background: rgb(63,94,251) !important;
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(70,132,252,1) 100%) !important;
}
.cta-btn-login-modal{
  padding: 5px !important;
  height: auto !important;
  background: rgb(63,94,251) !important;
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(70,132,252,1) 100%) !important;
}
.cta-btn-cancel-modal{
  padding: 5px !important;
  height: auto !important;
}
.cta-btn-login>span{
  font-size: 18px;
}

.no-account{
  font-size: 16px;
}
.cta-btn>span{
  font-size: 18px;
  text-transform: uppercase !important;
}
.sale-title{
  font-size: 40px !important;
}
.sale-text{
  font-size: 20px !important;
}

.row-container{
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(70,132,252,1) 100%);
}
.row-second{
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.row-third{
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
}

.price-tag{
  font-size: 40px !important;
}

.mainLayout .ant-layout-header {
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(70,132,252,1) 100%);
  padding: 0;
  height: auto;
  line-height: 1.6;
  color: #fafafa;
}
.logo > a{
  color: #fafafa !important;
}

.ant-card-head-title{
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
}

.ant-form-item-label>label{
  font-weight: 600;
  font-size: 16px !important;
}

